import styled from 'styled-components';
const ScHeaderNavigation = styled.nav`
  display: flex;
  flex: 1;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  a {
    text-decoration: none;
  }
  &.isMobile {
    top: var(--jf-header-size);
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: fixed;
    background-color: #fff;
  }
  .jfRHeader--nav-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    html[dir="rtl"] & {
      margin-left: 0;
      margin-right: auto;
    }
    &.isMobile {
      height: calc(100% - 72px);
      overflow-x: auto;
    }
    // A/B Test: contactSalesButtonOnHeader
    @media screen and (max-width: 520px) {
      &.is-trio-button {
        height: calc(100% - 129px);
      }
    }
    &-item {
      position: relative;
      list-style: none;
      & > a {
        color: var(--jf-header-nav-menu);
        padding: 0 16px;
        white-space: nowrap;
        position: relative;
        display: inline-block;
        font-size: 14px;
        letter-spacing: -0.084px;
        line-height: var(--jf-header-size);
        @media screen and (min-width: 1280px) {
          font-size: 16px;
          letter-spacing: -0.176px;
        }
        &:focus {
          outline: thin dotted #d5daff;
        }
      }
      @media screen and (min-width: 1025px) {
        & + li {
          margin-left: 6px;
          list-style: none;
          html[dir="rtl"] & {
            margin-left: 0;
            margin-right: 6px;
          }
        }
      }
      &.isBadge {
        & > a {
          display: inline-flex;
          align-items: center;
        }
      }
      &-badge {
        background-color: #64b200;
        color: #fff;
        padding: 0 4px;
        border-radius: 4px;
        font-size: 10px;
        letter-spacing: 0.1px;
        line-height: 16px;
        margin-left: 4px;
        html[dir="rtl"] & {
          margin-left: 0;
          margin-right: 4px;
        }
      }
      &-icon {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        html[dir="rtl"] & {
          margin-right: 0;
          margin-left: 6px;
        }
      }
      &-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-bottom: 4px;
        background-color: #EB4145;
        margin-left: 2px;
        html[dir="rtl"] & {
          margin-left: 0;
          margin-right: 2px;
        }
      }
      &.hasSubMenu {
        & > a {
          display: inline-flex;
          align-items: center;
          svg {
            margin-left: 8px;
            width: 8px;
            min-width: 8px;
            height: 4px;
            html[dir="rtl"] & {
              margin-left: 0;
              margin-right: 8px;
            }
          }
          @media screen and (max-width: 1024px) {
            font-size: 16px;
            font-weight: 500;
            &.isOpen {
              color: var(--jf-header-nav-menu-active);
            }
            svg {
              margin-left: auto;
              width: 16px;
              height: 8px;
              html[dir="rtl"] & {
                margin-left: 0;
                margin-right: auto;
              }
            }
          }
        }
        &:has(.jfRHeader--nav-sub-menu-item > a.isCurrent) {
          > a {
            color: var(--jf-header-nav-menu-active);
            &:after,
            &:before {
              width: 18%;
            }
          }
        }
      }
      &:not(.hasSubMenu) {
        & > a {
          .jfRHeader--nav.jfRHeader--nav-efc & {
            &:after,
            &:before {
              content: "";
              width: 0;
              height: 2px;
              position: absolute;
              bottom: 10px;
              z-index: -1;
              transition: width 0.2s ease;
            }
            &:before {
              border-radius: 1px 0 0 1px;
              right: 50%;
            }
            &:after {
              border-radius: 0 1px 1px 0;
              left: 50%;
            }
            @media screen and (min-width: 1025px) {
              &:hover {
                color: var(--jf-header-nav-menu-hover);
                &:after,
                &:before {
                  background-color: var(--jf-header-nav-menu-hover);
                  width: 18%;
                }
              }
            }
          }
          &.isCurrent {
            color: var(--jf-header-nav-menu-active);
            font-weight: 500;
            @media screen and (min-width: 1025px) {
              &:after,
              &:before {
                background-color: var(--jf-header-nav-menu-active);
                width: 18%;
              }
            }
          }
        }
      }
      &-button {
        a:not(.jfRHeader--custom-cta) {
          border-radius: 4px;
          font-size: 16px;
          padding: 8px 10px;
          color: #fff;
          display: inline-flex;
          white-space: nowrap;
          color: #fff;
          background-color: #ff6100;
          transition: all 0.2s ease;
          &:hover {
            background-color: #E55300;
          }
          @media screen and (max-width: 1024px) {
            margin-left: 16px;
            margin-right: 16px;
          }
        }
      }
      @media screen and (min-width: 1025px) {
        &:hover {
          & > a {
            color: var(--jf-header-nav-menu-hover);
          }
          a:not(.jfRHeader--custom-cta) + .jfRHeader--nav-sub-menu {
            &:not(.isOpen){
              display: flex;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      margin-left: 0;
      html[dir="rtl"] & {
        margin-right: 0;
      }
      flex-direction: column;
      align-items: flex-start;
      &-item {
        width: 100%;
        & + .jfRHeader--nav-menu-item {
          border-top: 1px solid #e3e5f5;
        }
        & > a {
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          --jf-header-nav-menu: #0a1551;
        }
      }
    }
  }
  .jfRHeader--nav-action {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    &-item {
      position: relative;
      width: 100%;
      list-style: none;
      button, a {
        position: relative;
        padding: 0 16px;
        white-space: nowrap;
        font-weight: 400;
        display: block;
        border-radius: 4px;
        font-size: 14px;
        letter-spacing: -0.072px;
        line-height: 16px;
        padding: 8px 12px;
        border: 0;
        outline: none;
        background-color: transparent;
        @media screen and (min-width: 1280px) {
          padding: 12px 20px;
          letter-spacing: -0.084px;
        }
        &.jfRHeader--nav-action-list-login {
          line-height: calc(var(--jf-header-size) - 16px);
          color: var(--jf-header-nav-menu);
          @media screen and (min-width: 1280px) {
            line-height: calc(var(--jf-header-size) - 24px);
          }
          @media (hover: hover) {
            &:hover {
              color: var(--jf-header-nav-menu-hover);
            }
          }
        }
        &.jfRHeader--nav-action-list-signup {
          background-color: #ff6100;
          color: #fff;
          transition: all 0.2s ease;
          @media (hover: hover) {
            &:hover {
              background-color: #e15600;
            }
          }
        }
        // A/B Test: contactSalesButtonOnHeader
        &.jfRHeader--contact-sales {
          background-color: #0075E3;
          font-weight: 500;
          color: #fff;
          transition: all 0.2s ease;
          @media screen and (max-width: 1024px) {
            background-color: #0a1551;
            width: 100%;
            margin: 16px 16px 16px 8px;
          }
          @media (hover: hover) {
            &:hover {
              background-color: #0066c3;
            }
          }
        }
      }
      @media screen and (max-width: 1024px) {
        width: 50%;
        display: flex;
        button, a {
          &.jfRHeader--nav-action-list-login {
            width: 100%;
            line-height: 1;
            margin: 16px 8px 16px 16px;
            border: 1px solid #0a1551;
            color: #0a1551;
          }
          &.jfRHeader--nav-action-list-signup {
            width: 100%;
            margin: 16px 16px 16px 8px;
            background-color: #0a1551;
            border: 1px solid #0a1551;
          }
        }
      }
    }
    &.isNotLoggedIn {
      &:before {
        content: "";
        background: #e3e5f5;
        width: 1px;
        height: 40px;
        margin: 0 8px;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
    }
    &.isMobile {
      width: 100%;
      box-shadow: 0 0 16px rgba(41, 42, 61, 0.25);
      border-top: 1px solid #e3e5f5;
      .jfRHeader--nav-action-list {
        width: 100%;
        position: relative;
      }
    }
    &.isMobileOpen {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      justify-content: center;
      align-items: flex-start;
      border-radius: 40px 40px 0 0;
      overflow: hidden;
    }
    // A/B Test: contactSalesButtonOnHeader
    @media screen and (max-width: 520px) {
      &.is-trio-button {
        flex-direction: column;
        gap: 6px;
        padding: 12px;
        .jfRHeader--nav-action-item {
          width: 100%;
          button {
            margin: 0;
          }
        }
      }      
    }

  }

  .jfRHeader--nav-account-box {
    position: relative;
    &-avatar {
      button {
        width: var(--jf-header-size);
        height: var(--jf-header-size);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background-color: transparent;
        outline: none;
      }
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 2px solid #ff6100;
        object-fit: cover;
        object-position: center;
        &.dummy {
          background-color: #ff6100;
        }
        &[src=""] {
          text-indent: -9999px;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #0a1551;
            border: 2px solid #ff6100;
            box-sizing: border-box;
          }
        }
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    &-menu {
      position: absolute;
      right: 10px;
      top: 100%;
      width: 320px;
      border-radius: 10px;
      border: 1px solid #c8ceed;
      background-color: #fff;
      display: none;
      z-index: 1;
      html[dir="rtl"] & {
        right: auto;
        left: 10px;
      }
      .jNewHeader-subMenu {
        border-radius: 10px;
        overflow: hidden;
      }
      &.isVisible {
        display: block;
      }
      &:before {
        content: "";
        position: absolute;
        top: -7px;
        right: 17px;
        width: 12px;
        height: 12px;
        background-color: #fff;
        background-image: linear-gradient(135deg, #fff 50%, transparent 50%);
        border-top: 1px solid #c8ceed;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-left: 1px solid #c8ceed;
        transform: rotateZ(45deg);
        border-radius: 2px 0 0 0;
        animation: 0.25s arrowSlideIn ease-out forwards;
        html[dir="rtl"] & {
          right: auto;
          left: 17px;
        }
      }
      @media screen and (max-width: 1024px) {
        position: relative;
        right: auto;
        top: auto;
        width: 100%;
        border-radius: 0;
        border: 0;
        display: block;
        html[dir="rtl"] & {
          left: auto;
        }
        &:before {
          display: none;
        }
      }
    }
    &-mobile-btn {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 80px;
      z-index: 2;
      width: 100%;
      border: 0;
      outline: none;
      &:before {
        content: "";
        position: absolute;
        top: 12px;
        left: 50%;
        border: 2px solid #8b92ad;
        width: 10px;
        height: 10px;
        transform: translateY(-50%) rotate(45deg);
        border-radius: 4px 0 0 0;
        border-bottom: 0;
        border-right: 0;
      }
      &.isMobileOpen {
        &:before {
          transform: translateY(-50%) rotate(225deg);
        }
      }
      @media screen and (max-width: 1024px) {
        display: block;
      }
    }
  }
`;
export default ScHeaderNavigation;
