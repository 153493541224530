export const twoFactorTypeKeys = {
    sms: 'phone',
    authenticatorApp: 'totp'
};
export const Texts = {
    MY_FORMS: 'My Forms',
    PROFILE: 'Profile',
    SKIP_CREATE_ACCOUNT: 'Skip Create an Account',
    CONTINUE_DRAFT: 'Continue With Draft',
    DISCARD_START_BLANK: 'You can continue your draft or discard and start with a blank form',
    SAVED_DRAFT_SUBMISSION: 'You have a draft for this form.',
    HELLO_TEXT: 'Hello,',
    LOG_OUT: 'Log out',
    LOGOUT: 'Logout',
    LOGIN_TEXT: 'Log in',
    GET_SHARE_LINK: 'Get Shareable Link',
    EMAIL: 'Email',
    RE_EMAIL: 'Resend Email',
    COPIED_CLIPBOARD: 'Link copied to clipboard!',
    SENT_TO: 'Email sent to',
    DOESNT_SUPPORT_BROWSER: 'Your browser doesn\'t support this operation',
    SEND_LINK_COMPLETE: 'We’ve sent you an email with a link to complete your submission.',
    START_OVER: `Discard ${encodeURIComponent('&')} Start Over`,
    SAVE_PROGRESS: 'Save your progress',
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    DONT_HAVE_ACCOUNT: 'Don’t have an account?',
    ARE_YOU_JOTFORM_ENTERPRISE_USER: 'Are you a Jotform Enterprise user?',
    LOGIN_TO_JOTFORM_ENTERPRISE: 'Log into Jotform Enterprise',
    LOGIN_CAPITAL: 'LOG IN',
    SIGN_UP: 'Sign up',
    CREATE_ACCOUNT: 'Create an Account',
    OTHER_OPTIONS: 'More sign up options',
    SIGN_UP_EMAIL: 'Sign up with Email',
    SIGN_UP_EMAIL_LOWERCASE: 'Sign up with email',
    FULL_NAME: 'Full Name',
    NAME_TEXT: 'Name',
    EMAIL_TEXT: 'Email',
    PASSWORD_TEXT: 'Password',
    CONF_PASSWORD_TEXT: 'Confirm Password',
    TERMS_SERVICE: 'Terms of Service',
    TERMS_OF_USE: 'Terms of Use',
    PRIVACY_POLICY: 'Privacy Policy',
    AND_KEY: 'and',
    OR_KEY: 'OR',
    SUBMISSIONS_SAVED_DRAFT: 'Your form has been saved as a draft.',
    SEND_DRAFT_CONTINUE: 'We’ve sent you an email with a link to continue your form.',
    ENTER_EMAIL: 'If you want to continue your form later, please enter the email address you would like to send the link to:',
    EMAIL_NOT_BLANK: 'Email cannot be left blank',
    SEND_LINK: 'Send link',
    SEND: 'Send',
    BACK_KEY: 'Back',
    SAVE_SUBMISSION: 'Save Submission',
    SAVE_KEY: 'Save',
    SKIP_KEY: 'Skip',
    USERNAME_EMAIL: 'Username or Email',
    LOGIN_SAVE: 'Login and Save Submission',
    AGREE_SERVICE_POLICY: 'I agree to the [1[Terms of Service]] and [2[Privacy Policy]].',
    AGREE_FOR_TURKISH_USERS: 'I have read the [1[KVKK Clarification Text]] and I accept the processing of my personal data in accordance with the [2[Explicit Consent Text]].',
    MORE_HELP_CONTACT: 'If you need more help, please [1[contact our support team.]]',
    MORE_HELP_CONTACT_ENTERPRISE: 'If you need help, please contact with your administrator.',
    FORGOT_PASSWORD: 'Forgot password?',
    FORGOT_YOUR_PASSWORD: 'Forgot your password?',
    PASSWORD_RESET_SENT: 'Password reset instructions have been sent successfully via email.',
    FIELD_REQUIRED: 'This field is required',
    EMAIL_FIELD_REQUIRED: 'Username or email field is required',
    PASS_FIELD_REQUIRED: 'Password field is required',
    ENTER_A_PASSWORD: 'Please enter a password.',
    PASSWORD_OK: 'Password ok',
    PASSWORD_CONFIRMATION_OK: 'Password confirmation ok',
    PASSWORD_CANNOT_BLANK: 'Password cannot be left blank',
    PASS_MIN_CHAR_COUNT: 'Should have minimum 8 characters',
    PASS_INCL_CHARS: 'Should be a mix of upper case, lower case characters, and numbers or special characters',
    FIELDS_SHOULD_MATCH: 'Passwords don\'t match.',
    SECURE_PASS_DESC: 'Your password must contain at least',
    SECURE_PASS_REUSE: 'Your password must be different from your last 6 passwords',
    SECURE_PASS_REUSE_CONT: ' and contain at least',
    SECURE_PASS_CHAR_COUNT: '8 characters',
    SECURE_PASS_TEST_DESC: 'Your password must include at least',
    SECURE_PASS_UPPERCASE: '1 uppercase letter',
    SECURE_PASS_LOWERCASE: '1 lowercase letter',
    SECURE_PASS_NUMBER: '1 number',
    SECURE_PASS_SPECIAL_CHAR: '1 special character',
    SECURE_PASS_NO_LEADING_OR_TRAILING_WHITESPACE: 'No blank spaces, either at the beginning or at the end',
    PASS_DIFF_PREV: 'Should be different from your last 6 passwords',
    CONNECT_SALESFORCE: 'Connect with Salesforce',
    CONNECT_APPLE: 'Connect with Apple',
    CONNECT_GOOGLE: 'Connect with Google',
    CONNECT_MICROSOFT: 'Connect with Microsoft',
    CONNECT_FACEBOOK: 'Connect with Facebook',
    SIGN_UP_SALESFORCE: 'Sign up with Salesforce',
    SIGN_UP_SALESFORCE_MOBILE: 'Salesforce',
    SIGN_UP_APPLE: 'Sign up with Apple',
    SIGN_UP_GOOGLE: 'Sign up with Google',
    SIGN_UP_GOOGLE_MOBILE: 'Google',
    SIGN_UP_FACEBOOK: 'Sign up with Facebook',
    SIGN_UP_FACEBOOK_MOBILE: 'Facebook',
    SIGN_UP_MICROSOFT: 'Sign up with Microsoft',
    SIGN_UP_MICROSOFT_MOBILE: 'Microsoft',
    SIGN_UP_NOW: 'Sign Up Now!',
    SIGN_UP_NOW_NO_EXCLAMATION_MARK: 'Sign Up Now',
    LOGIN_WITH: 'Log in with',
    LOGIN_SALESFORCE: 'Login with Salesforce',
    SALESFORCE: 'Salesforce',
    LOGIN_GOOGLE: 'Login with Google',
    GOOGLE: 'Google',
    LOGIN_FACEBOOK: 'Login with Facebook',
    FACEBOOK: 'Facebook',
    LOGIN_APPLE: 'Login with Apple',
    APPLE: 'Apple',
    LOGIN_MICROSOFT: 'Login with Microsoft',
    MICROSOFT: 'Microsoft',
    ENTER_USERNAME_EMAIL: 'Enter your username or email',
    SEND_INSTRUCTIONS: 'Send Reset Instructions',
    GO_BACK_TEXT: 'Go back',
    BACK_TO_PRIMARY_METHOD: 'Back to Primary Method',
    CAN_NOT_ACCESS_MY_DEVICE: "I can't access my authentication device",
    // eslint-disable-next-line max-len
    RECOVER_ACCOUNT_TEXT: "If you can't access your authentication device or recovery codes, you can ask for assistance from Jotform support. You need to answer some security questions to recover your account.",
    RECOVER_ACCOUNT_MAIL_HAS_BEEN_SENT: 'Your request to have your account recovered has already been sent in. You are unable to submit another request to recover your account until it is finished.',
    RECOVER_YOUR_ACCOUNT: 'Recover Your Account',
    VIEW_SUPPORT_TICKET: 'View Support Ticket',
    VERIFICATION_EMAIL_SENT_TO: 'The verification email has been sent to',
    RECOVER_ACCOUNT_EMAIL_INFO_TEXT: 'Click on the link in the email to answer a few security questions.',
    RECOVER_ACCOUNT_NOTIFICATION_TEXT: 'After you submit, a member of our support staff will examine your responses and get in touch with you to offer assistance within 48 to 72 hours.',
    BACK_TO_LOGIN: 'Back to Login',
    VALID_EMAIL: 'Please type a valid email',
    SENDING: 'Sending...',
    AGREE_TERMS_CONDITIONS: 'Please agree terms and conditions',
    NEED_TO_ACCEPT_TERMS: 'You need to accept our Terms of Service and Privacy Policy to create an account.',
    CHECK_SPAM_FOLDER: 'If you don’t get the email, please check your spam folder.',
    GET_INSTRUCTION_EMAIL: 'You should get the password reset instructions via email soon.',
    OK_TEXT: 'OK',
    CHECK_EMAIL: 'Check your email',
    EMAIL_IS_USE: 'Email is in use:',
    EMAIL_IS_NOTVALID: 'Email is not valid.',
    CURRENT_PASSWORD: 'Current Password',
    SHOW_PASSWORD: 'Show Password',
    HIDE_PASSWORD: 'Hide Password',
    PLEASE_WAIT: 'Please wait',
    WELCOME_BACK: 'Welcome Back!',
    CREATE_MY_ACCOUNT: 'CREATE MY ACCOUNT',
    BY_CLICKING_YOU_AGREE: 'By clicking "Signup" you agree our [1[Terms of Service]] and [2[Privacy Policy]]',
    ERROR_TRY_AGAIN: 'There was an error. Please try again later.',
    VERIFY_ITS_YOU: 'Verify it\'s you!',
    TWO_FACTOR_DESCRIPTION: {
        [twoFactorTypeKeys.sms]: "We've sent an authentication code to [1[receiver]]. Please check your messages and enter the six-digit code to enable 2FA",
        [twoFactorTypeKeys.authenticatorApp]: 'Enter the six-digit code from your configured Authenticator App below.'
    },
    EMAIL_AUTHENTICATION_DESCRIPTION: "We've sent an authentication code to [1[receiver]]. Please check your inbox and enter the six-digit code.",
    VERIFICATION_CODE: 'Authentication code',
    RECOVERY_CODE: 'Recovery code',
    VERIFY: 'VERIFY',
    VERIFY_BUTTON_TEXT: 'Verify',
    NO_CODE: 'No code?',
    SEND_AGAIN: 'Send again',
    HAVING_PROBLEMS: 'Having problems?',
    SEE_OTHER_AUTHENTICATION_METHODS: 'See Other Methods',
    SEND_CODE_TO: {
        [twoFactorTypeKeys.sms]: 'Send code to [1[receiver]]',
        [twoFactorTypeKeys.authenticatorApp]: 'Use authenticator app'
    },
    USE_YOUR_RECOVERY_CODES: 'Use your recovery codes',
    USE_YOUR_AUTHENTICATOR_APP: 'Use code from Authenticator App',
    TWO_FACTOR_AUTHENTICATION: 'Two-Factor Authentication',
    EMAIL_AUTHENTICATION: 'E-mail Authentication',
    ENTER_RECOVERY_CODES: 'Enter one of your 6-digit recovery codes below.',
    DAILY_LIMIT: 'You\'ve reached the daily text message limit',
    INVALID_TOKEN: 'Invalid code; please try again',
    BACKUP_CODES_ERROR: 'You entered a wrong or already used recovery code',
    BLANK_VERIFICATION_CODE: 'Code cannot be blank.',
    GREETING_DESCRIPTION: '',
    SHOW_MORE: 'Show More',
    CHOOSE_A_METHOD: 'Choose a method',
    CHOOSE_A_METHOD_DESCRIPTION: 'Select a verification method to login your account',
    REACH_DAILY_LIMIT_FORCE_TO_EMAIL: 'Your verification code has been sent to your email due to you have reached the daily SMS limit.',
    USED_RECOVERY_CODES: 'Used recovery codes will be invalidated.',
    ENTER_YOUR_EMAIL: 'Enter your email',
    THANK_YOU: 'THANK YOU!',
    VIEW_COMPLETED_APPROVALS: 'View your completed approvals',
    COMPLETED_TASK: 'The task has been completed.',
    PLEASE_LOGIN: 'Please [1[Log in]].',
    EMAIL_ERROR: 'This email address is already associated with a Jotform account.',
    EMAIL_ERROR_ENTERPRISE: 'This email address is already associated with an account.',
    // Delete my account flow
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_YOUR_ACCOUNT: 'Are you sure you want to delete your account?',
    YOU_ARE_ABOUT_TO_DELETE_YOUR_ACCOUNT_NAMELY: 'You’re about to delete your account namely:',
    GET_IN_TOUCH_WITH_US: '[1[Get in touch]] with us if you have any questions.',
    YES_DELETE: 'Yes, Delete',
    CANCEL: 'Cancel',
    VERIFY_THAT_ITS_YOU_TO_DELETE_YOUR_ACCOUNT: 'Verify That It’s You to Delete Your Account',
    VERIFY_WITH: 'Verify with',
    PLEASE_ENTER_YOUR_PASSWORD: 'Please enter your password',
    YOU_HAVE_ENTERED_YOUR_CURRENT_PASSWORD_WRONG: 'You have entered your current password wrong',
    YOU_HAVE_VERIFIED_SUCCESSFULLY: 'You have verified successfully',
    DELETE_MY_ACCOUNT: 'Delete My Account',
    YOUR_ACCOUNT_COULD_NOT_BE_DELETED: 'Your account could not be deleted. Please try again.',
    YOU_COULD_NOT_BE_VERIFIED: 'You could not be verified. Try again later',
    VERIFY_DELETE: 'Verify',
    CONTINUE_LATER: 'We’ve emailed you a link you can use to continue it later.',
    DRAFT_SAVED: 'Submission saved as draft',
    INCOMPLETE_SUBMISSION: 'You have an incomplete submission for this form',
    DRAFT_TEXT: 'Discard this submission draft to start over, or continue with your saved draft.',
    DISCARD_DRAFT: 'Discard and start over',
    CONTINUE_WITH_DRAFT: 'Continue with draft',
    SAVE_AS_DRAFT: 'Sign up to save submission as a draft',
    PLEASE_WAIT_SAVE: 'Please wait while we save your submission.',
    SAVE_ERROR: "Your submission couldn't be saved",
    PLEASE_TRY_AGAIN: 'Please try again.',
    TRY_AGAIN: 'Try Again',
    GET_DRAFT_LINK: 'Get Draft Link',
    DRAFT_BEING_SAVED: 'Your submission is being saved',
    ENTER_EMAIL_ADDRESS: 'Enter your email address',
    PLEASE_ENTER_VALID_EMAIL: 'Please enter valid email',
    CONTINUE_FORM_LATER: 'If you want to continue your form later, please enter your email address.',
    TO_KEY: 'To',
    DONT_ACCESS_DRAFT: 'You don’t have access to this submission draft',
    FILL_OUT_DRAFT: 'Fill out the form to create a new submission.',
    CHANGE_ACCOUNT: 'Change Account',
    START_OVER_FORM: 'Start Over',
    NOT_FOUND: 'Submission draft not found',
    DELETED_DRAFT: 'This draft was deleted or has already been submitted.',
    USER_IS_SSO_ERROR: 'Email already in use by SSO user.'
};
export const AlertLabelTypes = {
    WARNING: 'WARNING',
    SUCCESS: 'SUCCESS',
    INFO: 'INFO'
};
export const prepareLoginFlowDefaultGreetings = ()=>{
    const isPartnershipPages = window.location.href.indexOf('/partnership/') >= 0;
    const isAffiliateSignup = window.location.href.indexOf('/affiliate/') >= 0;
    const isJotformForBeginners = window.location.href.indexOf('/jotform-for-beginners/') >= 0;
    if (isPartnershipPages) {
        return `You will need a free Jotform account
      in order to access your Dashboard. If you already have a Jotform account,
      please log in to your account first to be able to fill out your application.
      If you have more than one Jotform account, please use the registered account to the
      Jotform ${isAffiliateSignup ? 'Affiliate Program' : 'Solutions Program'}.`;
    }
    if (isJotformForBeginners) {
        return 'Create a free Jotform account to download the book, Jotform for Beginners.';
    }
    if (window.JOTFORM_ENV !== 'ENTERPRISE') {
        return 'Collect information, payments, and signatures with custom online forms.';
    }
    return '';
};
export const FORM_TEXTS = {
    confirmEmail: 'E-mail does not match',
    pleaseWait: 'Please wait...',
    validateEmail: 'You need to validate this e-mail',
    confirmClearForm: 'Are you sure you want to clear the form',
    lessThan: 'Your score should be less than or equal to',
    incompleteFields: 'There are incomplete required fields. Please complete them.',
    required: 'This field is required.',
    requireOne: 'At least one field required.',
    requireEveryRow: 'Every row is required.',
    requireEveryCell: 'Every cell is required.',
    email: 'Enter a valid e-mail address',
    alphabetic: 'This field can only contain letters',
    numeric: 'This field can only contain numeric values',
    alphanumeric: 'This field can only contain letters and numbers.',
    cyrillic: 'This field can only contain cyrillic characters',
    url: 'This field can only contain a valid URL',
    currency: 'This field can only contain currency values.',
    fillMask: 'Field value must fill mask.',
    uploadExtensions: 'You can only upload following files:',
    noUploadExtensions: 'File has no extension file type (e.g. .txt, .png, .jpeg)',
    uploadFilesize: 'File size cannot be bigger than:',
    uploadFilesizemin: 'File size cannot be smaller than:',
    gradingScoreError: 'Score total should only be less than or equal to',
    inputCarretErrorA: 'Input should not be less than the minimum value:',
    inputCarretErrorB: 'Input should not be greater than the maximum value:',
    maxDigitsError: 'The maximum digits allowed is',
    minCharactersError: 'The number of characters should not be less than the minimum value:',
    maxCharactersError: 'The number of characters should not be more than the maximum value:',
    freeEmailError: 'Free email accounts are not allowed',
    minSelectionsError: 'The minimum required number of selections is ',
    maxSelectionsError: 'The maximum number of selections allowed is ',
    pastDatesDisallowed: 'Date must not be in the past.',
    dateLimited: 'This date is unavailable.',
    dateInvalid: 'This date is not valid. The date format is {format}',
    dateInvalidSeparate: 'This date is not valid. Enter a valid {element}.',
    ageVerificationError: 'You must be older than {minAge} years old to submit this form.',
    multipleFileUploads_typeError: '{file} has invalid extension. Only {extensions} are allowed.',
    multipleFileUploads_sizeError: '{file} is too large, maximum file size is {sizeLimit}.',
    multipleFileUploads_minSizeError: '{file} is too small, minimum file size is {minSizeLimit}.',
    multipleFileUploads_emptyError: '{file} is empty, please select files again without it.',
    multipleFileUploads_uploadFailed: 'File upload failed, please remove it and upload the file again.',
    multipleFileUploads_onLeave: 'The files are being uploaded, if you leave now the upload will be cancelled.',
    multipleFileUploads_fileLimitError: 'Only {fileLimit} file uploads allowed.',
    dragAndDropFilesHere_infoMessage: 'Drag and drop files here',
    chooseAFile_infoMessage: 'Choose a file',
    maxFileSize_infoMessage: 'Max. file size',
    generalError: 'There are errors on the form. Please fix them before continuing.',
    generalPageError: 'There are errors on this page. Please fix them before continuing.',
    wordLimitError: 'Too many words. The limit is',
    wordMinLimitError: 'Too few words.  The minimum is',
    characterLimitError: 'Too many Characters.  The limit is',
    characterMinLimitError: 'Too few characters. The minimum is',
    ccInvalidNumber: 'Credit Card Number is invalid.',
    ccInvalidCVC: 'CVC number is invalid.',
    ccInvalidExpireDate: 'Expire date is invalid.',
    ccInvalidExpireMonth: 'Expiration month is invalid.',
    ccInvalidExpireYear: 'Expiration year is invalid.',
    ccMissingDetails: 'Please fill up the credit card details.',
    ccMissingProduct: 'Please select at least one product.',
    ccMissingDonation: 'Please enter numeric values for donation amount.',
    disallowDecimals: 'Please enter a whole number.',
    restrictedDomain: 'This domain is not allowed',
    ccDonationMinLimitError: 'Minimum amount is {minAmount} {currency}',
    requiredLegend: 'All fields marked with * are required and must be filled.',
    geoPermissionTitle: 'Permission Denied',
    geoPermissionDesc: 'Check your browser\'s privacy settings.',
    geoNotAvailableTitle: 'Position Unavailable',
    geoNotAvailableDesc: 'Location provider not available. Please enter the address manually.',
    geoTimeoutTitle: 'Timeout',
    geoTimeoutDesc: 'Please check your internet connection and try again.',
    selectedTime: 'Selected Time',
    formerSelectedTime: 'Former Time',
    cancelAppointment: 'Cancel Appointment',
    cancelSelection: 'Cancel Selection',
    noSlotsAvailable: 'No slots available',
    slotUnavailable: '{time} on {date} has been selected is unavailable. Please select another slot.',
    multipleError: 'There are {count} errors on this page. Please correct them before moving on.',
    oneError: 'There is {count} error on this page. Please correct it before moving on.',
    doneMessage: 'Well done! All errors are fixed.',
    invalidTime: 'Enter a valid time',
    doneButton: 'Done',
    reviewSubmitText: 'Review and Submit',
    nextButtonText: 'Next',
    prevButtonText: 'Previous',
    seeErrorsButton: 'See Errors',
    notEnoughStock: 'Not enough stock for the current selection',
    notEnoughStock_remainedItems: 'Not enough stock for the current selection ({count} items left)',
    soldOut: 'Sold Out',
    justSoldOut: 'Just Sold Out',
    selectionSoldOut: 'Selection Sold Out',
    subProductItemsLeft: '({count} items left)',
    startButtonText: 'START',
    submitButtonText: 'Submit',
    submissionLimit: 'Sorry! Only one entry is allowed. <br> Multiple submissions are disabled for this form.',
    reviewBackText: 'Back to Form',
    seeAllText: 'See All',
    progressMiddleText: 'of',
    fieldError: 'field has an error.',
    error: 'Error'
};
